import React from "react";
import { PageProps } from "gatsby";

const EmailPage: React.FC<PageProps> = ({ children }) => (
  <main className="full-centered">
    <h1>Email</h1>
    <form>
      <label id="email-label">Your Email-Address</label>
      <input
        required
        aria-labelledby="email-label"
        id="email"
        name="email"
        type="email"
        placeholder="name@example.com"
      />
    </form>
  </main>
);

export default EmailPage;
